.logo {
  width: 200px;
  height: auto;
  padding: 32px;
  opacity: 0.25;
}

.logoContainer:hover {
  background-color: rgba(144, 144, 150, 0.25);
}

.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #181818;
}

.navItem {
  border-top: 1px solid rgba(144, 144, 150, 0.25);
}

.navItem:hover {
  background-color: rgba(144, 144, 150, 0.25);
  color: rgba(27, 146, 67, 0.75);
}

.navItem p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: rgba(144, 144, 150, 0.75);
  padding: 12.5%;
  text-decoration: none;
}

.navItem p:hover {
  color: rgba(27, 146, 67, 0.75);
  font-weight: bold;
  font-size: 1.2rem;
}

.iconContainer {
  display: flex;
  justify-content: space-evenly;

  margin-bottom: 32px;
}
.icon {
  color: rgba(144, 144, 150, 0.75);
}
.icon:hover {
  color: rgba(27, 146, 67, 0.75);
}
