.container {
  width: 100%;
  background-color: #1d1d1d;
  border-left: 0.5px solid rgba(144, 144, 150, 0.25);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.headerWrap {
  margin: 0 5%;
  padding: 16px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  color: rgb(255, 255, 255);
}

.details {
  margin: 0 5%;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  padding: 16px;
  color: rgb(255, 255, 255);
  line-height: 1.5;
}

.details p {
  margin-bottom: 12px;
}
.details a {
  text-decoration: none;
}


.aboutContainer {
  margin-bottom: 24px;
}

.btn {
  
  text-align: center;
  width: 200px;
  height: 50px;
  border: 0.5px solid rgba(27, 146, 67, 0.75);
  border-radius: 8px;
}

.btn p {
  color: rgba(27, 146, 67, 0.75);
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Open Sans Condensed", sans-serif;
  padding: 4px 0;
}

.btn:hover {
  background-color: rgba(27, 146, 67, 0.75);
  border: 2px solid #eee;
}

.btn p:hover {
  color: #181818;
}
