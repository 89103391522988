.container {
  width: 100%;
  height: 100vh;
  background-color: #1d1d1d;
  border-left: 1px solid rgba(144, 144, 150, 0.25);
}

.container h1 {
  margin-top: 13%;
  margin-left: 5%;
  margin-bottom: 5%;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  color: rgb(255, 255, 255);
}

.projectsContainer {
  margin-left: 5%;
  display: flex;
  justify-content: space-evenly;
}

.imgContainer {
  max-height: 169px;
  margin: 8px 0
}

.card {
  width: 30%;
  min-height: 300px;
  display: flex;
  flex-flow: column;
  text-align: center;
}



.card img {
  width: 100%;
  max-height: 169px;
  object-fit: cover;
  
  
}

.card img:hover {
  border-radius: 12px;
}
.card a {
  text-decoration: none;
}
.card h3 {
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  color: rgb(255, 255, 255);
}
.card h3:hover {
  color: rgba(27, 146, 67, 0.75);
}

.card p {
  font-family: "Montserrat", sans-serif;
  padding-top: 8px;
  line-height: 1.4;
  color: rgb(255, 255, 255);
}
