.container {
  width: 100%;
  background-color: #1d1d1d;
  border-left: 1px solid rgba(144, 144, 150, 0.25);
  display: flex;
}

.contentLeft {
  width: 45%;
  height: 100%;
  margin-left: 5%;
}

.contentLeft h1 {
  margin-bottom: 5%;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  color: rgb(255, 255, 255);
}
.contentLeft p {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  color: rgba(144, 144, 150, 0.75);
  margin: 2% 0;
}

.circleContainer {
  height: 15%;
  display: flex;
}

.gray {
  margin-top: 2%;
  margin-right: 5%;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 100%;
}
.gray:hover {
  width: 60px;
  height: 60px;
}

.green {
  margin-top: 2%;
  margin-right: 5%;
  width: 50px;
  height: 50px;
  background-color: rgba(27, 146, 67, 0.75);
  border-radius: 100%;
}
.green:hover {
  width: 60px;
  height: 60px;
}

.black {
  margin-top: 2%;
  margin-right: 5%;
  width: 50px;
  height: 50px;
  background-color: #181818;
  border-radius: 100%;
}

.black:hover {
  width: 60px;
  height: 60px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 50px;
  border: 0.5px solid rgba(27, 146, 67, 0.75);
  border-radius: 8px;
}

.btn p {
  color: rgba(27, 146, 67, 0.75);
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Open Sans Condensed", sans-serif;
  padding: 4px 0;
}

.btn:hover {
  background-color: rgba(27, 146, 67, 0.75);
  border: 2px solid #eee;
}

.btn p:hover {
  color: #181818;
}

.heroImg {
  border-radius: 12px;
}

.heroImg img {
  margin-top: 35%;
  width: 100%;
  border-radius: 12px;
  opacity: 0.3;
}
