.container {
  width: 100%;
  height: vh 1;
  background-color: #1d1d1d;
  border-left: 1px solid rgba(144, 144, 150, 0.25);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.headerWrap {
  width: 90%;
  margin: 0 auto;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  color: rgb(255, 255, 255);
}

.skillsContainer {
  display: flex;
  width: 90%;
  flex-flow: row wrap;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
}

.flex_wrap {
  display: flex;
  margin: 16px 0;
  flex-basis: 25%;
  flex-flow: column wrap;
  align-items: center;
  
}

.skillWrap {
  height: 100%;
}
.iconWrap {
  justify-content: center;
}
.icon {
  color: rgba(144, 144, 150, 0.75);
}
.icon:hover {
  color: rgba(27, 146, 67, 0.75);
}

.skillLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-top: 16px;
  
}
