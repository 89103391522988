.container {
  width: 100%;
  height: 100vh;
  background-color: #1d1d1d;
  border-left: 1px solid rgba(144, 144, 150, 0.25);
  display: flex;
}

.formContainer {
  margin-top: 7%;
}

.headerWrap h1 {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  color: rgb(255, 255, 255);
  padding-bottom: 24px;
  margin-top: 13%;
}

.information {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: rgb(255, 255, 255);
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.information h3 {
  margin-top: 43%;
  margin-bottom: 5%;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: rgb(255, 255, 255);
}


.information p {
  color: rgb(255, 255, 255);
  line-height: 2;
  font-family: "Montserrat", sans-serif;
}

.formContainer {
  margin-left: 5%;
  width: 45%;
}
.email:hover {
  color: rgba(27, 146, 67, 0.75);
}

.phone:hover {
  color: rgba(27, 146, 67, 0.75);
}

.messageChars {
  margin-top: 1em;
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
}

input {
  border-radius: 8px;
  height: 36px;
  border: 2px solid #294d31;
  width: 75%;
  margin-bottom: 2%;
}

textarea {
  border-radius: 8px;
  border: 2px solid #294d31;
  height: 10em;
  width: 75%;
}

.btn {
  margin: 16px 0;
  text-align: center;
  background-color: #1d1d1d;
  color: rgba(27, 146, 67, 0.75);
  width: 200px;
  height: 50px;
  border: 0.5px solid rgba(27, 146, 67, 0.75);
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Open Sans Condensed", sans-serif;
  padding: 4px 0;
}


.btn:hover {
  background-color: rgba(27, 146, 67, 0.75);
  border: 2px solid #eee;
  color: #181818;
}


div[role="alert"] {
  color: rgb(134, 50, 50);
  margin-bottom: 1em;
}
